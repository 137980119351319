<template>
    <v-sheet class="fill-height" color="transparent">
        <v-lazy
            :v-model="user.purchases"
            :options="{
                threshold: 0.5,
            }"
            class="fill-height"
        >
            <v-card
                style="background-color: #fafafa; border-radius: 10px"
                flat
                class="ma-0 pa-0"
            >
                <p
                    v-if="user.name == 'On Hold'"
                    class="ma-0 pa-3 pb-1 d-flex font-weight-bold"
                    style="color: #EDC43B;"
                >
                    ON HOLD
                </p>
                <v-row v-else class="d-flex ma-0 pa-0">
                    <v-avatar class="ml-3 mt-3 pa-0" size="28">
                        <v-img
                            v-if="profilePicUrl"
                            :src="profilePicUrl"
                            :alt="user.name"
                        />
                        <v-icon v-else x-large color="#707070"
                            >mdi-account-circle</v-icon
                        >
                    </v-avatar>
                    <div class="ma-0 pa-2 pb-1">
                        <p class="ma-0 pa-0 font-weight-bold">
                            {{ user.name }}
                        </p>
                        <p class="ma-0 pa-0 mt-n1">
                            {{ user.role }}
                        </p>
                    </div>
                </v-row>
                <v-row class="flex-nowrap ma-0 pa-0">
                    <draggable
                        class="list-group overflow-y-auto px-2 py-0 ma-0"
                        :style="`height:${height - 68}px; width: 100%`"
                        v-model="user.purchases"
                        group="purchases"
                        @change="draggableLog"
                    >
                        <div
                            class="list-group-item py-0 mx-0 my-3 px-1"
                            v-for="(request, index) in userPurchases"
                            :key="index"
                        >
                            <v-sheet
                                v-if="request"
                                min-height="94"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="request"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                    class="fill-height"
                                >
                                    <PurchasesTask
                                        :request="request"
                                        @openAssignBuyer="openAssignBuyer"
                                    />
                                </v-lazy>
                            </v-sheet>
                        </div>
                    </draggable>
                </v-row>
            </v-card>
        </v-lazy>
        <!--USERS-->
        <v-dialog persistent max-width="400px" v-model="selectBuyer">
            <AssignBuyer
                v-if="selectBuyer"
                :requestId="selectedRequest"
                :users="users"
                @close="closeAssignBuyer"
            />
        </v-dialog>
    </v-sheet>
</template>

<script>
import API from '@/services/api'
import draggable from 'vuedraggable'
import { loadImage } from '@/helpers/imageHandler'
import { mapMutations } from 'vuex'

export default {
    name: 'PurchaseUserDraggable',
    components: {
        draggable,
        PurchasesTask: () =>
            import('@/components/Purchasing/PurchasesTask.vue'),
        AssignBuyer: () => import('@/components/Purchasing/AssignBuyer.vue'),
    },
    props: {
        user: Object,
        users: Array,
        height: Number,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            profilePicUrl: null,
            selectedTask: {},
            viewTaskDetails: false,
            selectBuyer: false,
            selectedRequest: undefined,
        }
    },
    computed: {
        userPurchases() {
            let purchases = this.user.purchases
            if (this.user.id == '123') {
                purchases = this.user.purchases.filter(p => p.readyForPricing)
            }
            return purchases
        },
    },
    async mounted() {
        try {
            if (this.user.picture) {
                try {
                    const path = `${this.companyId}/${this.folderUsersPictures}`
                    this.profilePicUrl = await loadImage(
                        path,
                        this.user.picture
                    )
                } catch (error) {
                    console.log(error.message)
                }
            }
        } catch (error) {
            this.setItemsError(error.message)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openAssignBuyer(requestId) {
            this.selectedRequest = requestId
            this.selectBuyer = true
        },
        closeAssignBuyer() {
            this.selectBuyer = false
            this.selectedRequest = undefined
        },
        draggableLog: async function(evt) {
            try {
                if (evt.added) {
                    await API.assignRequest(evt.added.element.id, {
                        buyer: this.user.name == 'On Hold' ? '' : this.user.id,
                        status:
                            this.user.name == 'On Hold' ? 'onHold' : 'pricing',
                        notificationId: 'pFDh25EcR74EMiuypq8M',
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>
<style scoped>
.borderRounded {
    border-radius: 15px;
}

.underlineTag {
    text-decoration: underline #3277d1;
    font-weight: bold;
    font-size: 13px;
    color: #3277d1;
}
</style>
