<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-0"
        ref="container"
        style="height:100%"
    >
        <v-card
            flat
            :class="{
                'overflow-x-auto': $vuetify.breakpoint.smAndDown,
                '': $vuetify.breakpoint.mdAndUp,
            }"
        >
            <div>
                <v-row class="flex-nowrap ma-0">
                    <v-col
                        class="pl-2 pr-0 py-0 ma-0"
                        v-for="user of filterUsers"
                        :key="user.id"
                        cols="8"
                        xl="3"
                        lg="3"
                        md="5"
                    >
                        <PurchaseUserDraggable
                            :height="height"
                            :user="user"
                            :users="users"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import API from '@/services/api'
import PurchaseUserDraggable from '@/components/Purchasing/PurchaseUserDraggable.vue'
import { firestore } from '@/services/firebase'
import { mapMutations } from 'vuex'

export default {
    name: 'PurchasingAssign',
    components: {
        PurchaseUserDraggable,
    },
    data: () => ({
        loading: false,
        users: [
            {
                id: '123',
                name: 'On Hold',
                purchases: [],
            },
        ],
        height: 0,
        onHold: [],
        onHold2: [],
        listener: null,
        companyId: JSON.parse(localStorage.getItem('company')),
        userId: JSON.parse(localStorage.getItem('userId')),
    }),
    computed: {
        filterUsers() {
            return this.users
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            const {
                data: { users },
            } = await API.getUsers()
            users.forEach(user => {
                if (user.permissions.includes('pricing')) {
                    this.users.push({ ...user, purchases: [] })
                }
            })
            let PurchasesQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', 'in', ['inProgress', 'pricing', 'onHold'])
            this.listener = await PurchasesQuery.onSnapshot(
                async docSnapshot => {
                    docSnapshot.docChanges().forEach(async change => {
                        const request = Object.assign(change.doc.data(), {
                            id: change.doc.id,
                        })
                        if (change.type === 'added') {
                            if (request.buyer) {
                                const index = this.users.findIndex(
                                    user => user.id == request.buyer
                                )
                                if (index >= 0) {
                                    this.users[index].purchases = this.users[
                                        index
                                    ].purchases
                                        ? this.users[index].purchases
                                        : []
                                    this.users[index].purchases.push(request)
                                } else {
                                    this.users[0].purchases.push(request)
                                }
                            } else {
                                this.users[0].purchases.push(request)
                            }
                        }
                        if (change.type === 'modified') {
                            if (request.buyer) {
                                for (const user of this.users) {
                                    if (user.id == request.buyer) {
                                        const index = user.purchases.findIndex(
                                            purchase =>
                                                purchase.id == request.id
                                        )
                                        if (index >= 0) {
                                            user.purchases.splice(
                                                index,
                                                1,
                                                request
                                            )
                                        } else {
                                            user.purchases.push(request)
                                        }
                                    } else {
                                        for (const purchaseIndex in user.purchases) {
                                            if (
                                                request.id ==
                                                    user.purchases[
                                                        purchaseIndex
                                                    ].id &&
                                                request.buyer !== user.id
                                            ) {
                                                user.purchases.splice(
                                                    purchaseIndex,
                                                    1
                                                )
                                                break
                                            }
                                        }
                                    }
                                }
                            } else {
                                let index = -1
                                for (let user of this.users) {
                                    index = user.purchases.findIndex(
                                        p => p.id == request.id
                                    )
                                    if (index >= 0) {
                                        if (user.name != 'On Hold') {
                                            user.purchases.splice(index, 1)
                                            this.users[0].purchases.push(
                                                request
                                            )
                                        } else {
                                            this.users[0].purchases.splice(
                                                index,
                                                1,
                                                request
                                            )
                                        }
                                        break
                                    }
                                }
                            }
                        }
                        if (change.type === 'removed') {
                            if (request.buyer) {
                                const index = this.users.findIndex(
                                    user => user.id == request.buyer
                                )
                                if (index >= 0) {
                                    const taskIndex = this.users[
                                        index
                                    ].purchases.findIndex(
                                        task => task.id == request.id
                                    )
                                    if (taskIndex >= 0) {
                                        this.users[index].purchases.splice(
                                            taskIndex,
                                            1
                                        )
                                        this.users.splice(index, 1, {
                                            ...this.users[index],
                                        })
                                    }
                                }
                            } else {
                                const index = this.onHold.findIndex(
                                    task => task.id == request.id
                                )
                                if (index >= 0) {
                                    this.onHold.splice(index, 1)
                                }
                            }
                        }
                    })
                }
            )
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onHoldLog: async function(evt) {
            window.console.log(evt)
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight + 19
        },
    },
}
</script>

<style></style>
